<template>
  <v-app>
    <div class="d-flex">
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Filter"
        dense
        clearable
      ></v-text-field>
    </div>
    <v-card class='mb-6'>
      <v-data-table
        hide-default-footer
        fixed-header
        height="70vh"
        sort-by='name'
        :search='search'
        :headers='headers'
        :items='items'
        :header-props="{
          sortIcon: 'mdi-chevron-down',
        }"
        :must-sort='true'
        :items-per-page='10000'
        @click:row="navigateToNew"
      >
        <template v-slot:[`item.templates`]="{ item }">
          <div v-for="(template, index) in item.templates" :key="index" class="no-break">
            {{template}}
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    search: ''
  }),
  computed: {
    ...mapState('todo', [
      'headers',
      'items',
    ]),
  },
  methods: {
    // @note this.$router is not available in the vuex store, so navigation's handled here.
    navigateToNew({ index }) {
      this.$router.push(`/items/new/${index}`)
    },
  },
}
</script>
<style lang='scss' scoped>
  ::v-deep {
    tr {
      cursor: pointer;
    }
    .text-start {
      text-transform: capitalize;
    }
    .v-application--wrap {
      min-height: auto;
    }
    .v-text-field .v-label {
      z-index: 100;
    }
    .v-data-table-header th {
      text-align: left;
      color: white !important;
      background-color: #220a5a !important;
      &:first-child {
        border-top-left-radius: 4px;
        padding-left: 34px;
      }
      &:last-child {
        border-top-right-radius: 4px;
      }
      &.active,
      & .v-data-table-header__icon {
        color: #ffe300 !important;
      }
      &.sortable {
        white-space: nowrap;
      }
    }
    .v-data-table tbody tr {
      & td {
        padding-top: 18px !important;
        padding-bottom: 18px !important;

        &:first-child {
          padding-left: 34px;
          background-color: inherit
        }
        &:last-child {
          padding-right: 34px;
        }
      }
    }
    .no-break {
      white-space: nowrap;
    }
  }
</style>
